'use client'

import SvgGoogleG from '@/assets/svg/GoogleG.tsx'
import SvgMicrosoft from '@/assets/svg/Microsoft.tsx'
import {
  autofillAuthentication,
  browserSupportsWebAuthn,
} from '@/modules/User/Authenticator/webauthnClientHelpers.ts'
import Button from '@/ui/Button.tsx'
import FormError from '@/ui/FormError.tsx'
import clearBrowserStorage from '@/utils/auth/clearBrowserStorage.ts'
import useSession from '@/utils/auth/useSession.ts'
import { authClient } from '@/utils/authClient.ts'
import { zodResolver } from '@hookform/resolvers/zod'
import { KeyRound } from 'lucide-react'
import { useRouter } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { match } from 'ts-pattern'
import { z } from 'zod'

const loginSchema = z.object({
  email: z.string().trim().email().min(1, { message: 'Email is required' }),
})
const otpSchema = z.object({
  otp: z.string().trim().min(1, { message: 'This field is required' }),
})

export default function LoginForm() {
  const { session, refetchSession } = useSession()
  const router = useRouter()

  const formRef = useRef<HTMLFormElement>(null)

  const [loadingEmail, setLoadingEmail] = useState(false)
  const [loadingGoogle, setLoadingGoogle] = useState(false)
  const [loadingMicrosoft, setLoadingMicrosoft] = useState(false)
  const [loadingPasskey, setLoadingPasskey] = useState(false)
  const [hasSentEmail, setHasSentEmail] = useState(false)

  const loginForm = useForm<z.infer<typeof loginSchema>, any, any>({
    resolver: zodResolver(loginSchema),
  })

  const otpForm = useForm<z.infer<typeof otpSchema>, any, any>({
    resolver: zodResolver(otpSchema),
  })

  useEffect(() => {
    async function autofill() {
      try {
        // from: https://www.better-auth.com/docs/plugins/passkey#preload-the-passkeys
        if (
          !PublicKeyCredential.isConditionalMediationAvailable ||
          !PublicKeyCredential.isConditionalMediationAvailable()
        ) {
          return
        }

        // Can't use the `signIn` function provided by better-auth here
        await autofillAuthentication()

        const data = await authClient.signIn.passkey({
          autoFill: true,
        })

        if (!data?.error) {
          refetchSession()
          router.replace('/app/home')
        }
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          return
        }
      }
    }

    autofill()
  }, [])

  useEffect(() => {
    if (!session || !!session?.user?.deactivatedAt) {
      clearBrowserStorage()
    }
  })

  return (
    <div className="flex h-64 flex-col self-stretch">
      {hasSentEmail ? (
        <div className="flex flex-col gap-4">
          <p className="text-center">
            We've sent a one-time password to <br />
            <strong>{loginForm.getValues().email}</strong>.
          </p>

          <p className="text-center">Enter that code below to log in.</p>

          <form
            autoComplete="off"
            className="mt-2 flex flex-col gap-4"
            onSubmit={otpForm.handleSubmit(
              async (data: z.infer<typeof otpSchema>) => {
                const { error } = await authClient.signIn.emailOtp({
                  email: loginForm.getValues().email,
                  otp: data.otp,
                })

                if (error) {
                  otpForm.setError('otp', {
                    type: 'manual',
                    message: match(error.code)
                      .with('INVALID_OTP', () => 'Invalid one-time password')
                      .with(
                        'OTP_EXPIRED',
                        () =>
                          'This one-time password has expired. You can refresh the page and generate a new one.',
                      )
                      .otherwise(() => 'Invalid one-time password'),
                  })
                } else {
                  refetchSession()
                  router.replace('/app/home')
                }
              },
            )}
          >
            <div className="flex flex-col gap-2">
              <input
                type="text"
                placeholder="One-time password"
                {...otpForm.register('otp')}
                className="w-full border-gray-900 text-gray-900"
              />

              <FormError form={otpForm} field="otp" />
            </div>

            <Button
              color="blue"
              loading={otpForm.formState.isSubmitting}
              type="submit"
              className="w-full"
            >
              Log In
            </Button>
          </form>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <Button
            color="white"
            className="border border-gray-900"
            loading={loadingGoogle}
            onClick={async () => {
              setLoadingGoogle(true)

              const { error } = await authClient.signIn.social({
                provider: 'google',
                callbackURL: '/app/home',
              })

              if (error) {
                loginForm.setError('email', {
                  type: 'manual',
                  message:
                    'Sorry, something went wrong. To log in, please enter your email to generate a one-time password.',
                })
              }

              setLoadingGoogle(false)
            }}
          >
            <SvgGoogleG className="mr-3 inline-block h-5 w-5 shrink-0" />
            Continue with Google
          </Button>

          <Button
            color="white"
            className="border border-gray-900"
            loading={loadingMicrosoft}
            onClick={async () => {
              setLoadingMicrosoft(true)
              await authClient.signIn.social({
                provider: 'microsoft',
                callbackURL: '/app/home',
              })
            }}
          >
            <SvgMicrosoft className="mr-3 inline-block h-5 w-5 shrink-0" />
            Continue with Microsoft
          </Button>

          <form
            action="/api/auth/callback/passkey"
            method="POST"
            ref={formRef}
            onSubmit={async (e) => {
              e.preventDefault()

              if (!browserSupportsWebAuthn()) {
                toast.error('Passkeys are not supported on this browser')
                return
              }

              setLoadingPasskey(true)

              await authClient.signIn.passkey({
                fetchOptions: {
                  onSuccess: () => {
                    refetchSession()
                    router.replace('/app/home')
                  },
                  onError: (_error) => {
                    loginForm.setError('email', {
                      type: 'manual',
                      message:
                        'Sorry, something went wrong. To log in, please enter your email to generate a one-time password.',
                    })
                  },
                },
              })

              setLoadingPasskey(false)
            }}
          >
            <Button
              color="white"
              className="w-full border border-gray-900"
              loading={loadingPasskey}
              type="submit"
            >
              <KeyRound
                absoluteStrokeWidth
                strokeWidth={1.5}
                size={20}
                className="mr-3 inline-block shrink-0 text-gray-400"
              />
              Continue with passkey
            </Button>
          </form>

          <div className="my-2 flex items-center justify-between gap-4">
            <hr className="flex-1 border-gray-400" />

            <span>or</span>

            <hr className="flex-1 border-gray-400" />
          </div>

          <form
            className="flex flex-col gap-4"
            onSubmit={loginForm.handleSubmit(
              async (data: z.infer<typeof loginSchema>) => {
                setLoadingEmail(true)

                const { error } = await authClient.emailOtp.sendVerificationOtp(
                  {
                    email: data.email,
                    type: 'sign-in',
                  },
                )

                if (error) {
                  loginForm.setError('email', {
                    type: 'manual',
                    message: match(error.code)
                      .with(
                        'NOT_FOUND',
                        () =>
                          'A Sirvatus account does not exist for the provided credentials. You must be invited by an admin of an existing organization in order to create a Sirvatus account.',
                      )
                      .otherwise(
                        () =>
                          'Sorry, something went wrong. To log in, please enter your email to generate a one-time password.',
                      ),
                  })
                } else {
                  setHasSentEmail(true)
                }

                setLoadingEmail(false)
              },
            )}
          >
            <div className="flex flex-col gap-2">
              <input
                type="email"
                placeholder="Email"
                {...loginForm.register('email')}
                autoComplete="email webauthn"
                className="w-full border-gray-900 text-gray-900"
              />

              <FormError form={loginForm} field="email" />
            </div>

            <Button
              color="blue"
              loading={loadingEmail}
              type="submit"
              className="w-full"
            >
              Send one-time password
            </Button>
          </form>
        </div>
      )}
    </div>
  )
}
