import * as idbKeyval from 'idb-keyval'
import isBrowser from '../common/isBrowser.ts'

/**
 * Clear all user-specific data stored in the browser.
 */
export default async function clearBrowserStorage() {
  if (!isBrowser()) {
    return
  }

  // Cookies
  const cookies = ['better-auth.companySlug']

  for (const cookie of cookies) {
    const name = cookie.includes('=')
      ? cookie.slice(0, cookie.indexOf('='))
      : cookie

    document.cookie = `${name}=; path=/; max-age=0`
  }

  // Local storage
  for (const key of Object.keys(localStorage)) {
    if (key.startsWith('sirvatus.')) {
      localStorage.removeItem(key)
    }
  }

  // Session storage
  for (const key of Object.keys(sessionStorage)) {
    if (key.startsWith('sirvatus.')) {
      sessionStorage.removeItem(key)
    }
  }

  // IndexedDB
  await idbKeyval.clear()
}
