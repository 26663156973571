import type { SessionCompany } from '@/modules/Company/Company/types.ts'
import type { SessionUser } from '@/modules/User/User/types.ts'
import type { Session as BetterAuthSession } from 'better-auth'
import { useContext } from 'react'
import { SessionContext } from './useLayoutSessionContext.ts'

export type Session = Omit<BetterAuthSession, 'expiresAt'> & {
  company: SessionCompany
  user: SessionUser
}

/**
 * Custom hook that serializes the session for client-side use.
 */
export default function useSession() {
  if (!SessionContext) {
    throw new Error('useSession must be used within a SessionContext.Provider')
  }

  const values = useContext(SessionContext)
  const session = values.session!
  const { updateSession, refetchSession } = values

  return {
    session,
    refetchSession,
    updateSession,
  }
}
