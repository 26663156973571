import isBrowser from '@/utils/common/isBrowser.ts'
import {
  browserSupportsWebAuthnAutofill,
  startAuthentication,
  startRegistration,
} from '@simplewebauthn/browser'
import type {
  AuthenticationResponseJSON,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationResponseJSON,
} from '@simplewebauthn/types'
export {
  browserSupportsWebAuthn,
  browserSupportsWebAuthnAutofill,
} from '@simplewebauthn/browser'

type ProviderId = 'passkey' | 'webauthn'

type WebAuthnAction = 'register' | 'authenticate'

// Path to the server's Auth.js routes
const AUTH_PATH = '/api/auth'

/**
 * 1. Fetch webauthn options from the server
 *
 * Omitting the action will have Auth.js's Passkey/Webauthn provider to infer the action.
 */
export async function getWebauthnOptions<Action extends WebAuthnAction>({
  action,
  formFields = {},
}: {
  action?: Action
  formFields?: Record<string, string>
}) {
  const url = new URL(
    `${AUTH_PATH}/passkey/generate-${action}-options`,
    isBrowser() ? window.location.href : 'https://www.sirvatus.com',
  )
  for (const [fieldName, fieldValue] of Object.entries(formFields)) {
    url.searchParams.append(fieldName, fieldValue)
  }
  const res = await fetch(url, { method: 'POST' })
  if (!res.ok) {
    throw new Error('Failed to fetch options')
  }
  const options = (await res.json()) as Action extends 'authenticate'
    ? PublicKeyCredentialRequestOptionsJSON
    : Action extends 'register'
      ? PublicKeyCredentialCreationOptionsJSON
      :
          | PublicKeyCredentialRequestOptionsJSON
          | PublicKeyCredentialCreationOptionsJSON
  return options
}

/**
 * 2. Start the registration/authentication flow.
 *
 * Registration is for assigning a new key to an existing user.
 *
 * Authentication is for logging in with an existing key.
 */

export async function startPasskeyRegistration(
  options: PublicKeyCredentialCreationOptionsJSON,
) {
  return startRegistration({ optionsJSON: options })
}

export async function startPasskeyAuthentication(
  options: PublicKeyCredentialRequestOptionsJSON,
  autofill?: boolean,
) {
  return startAuthentication({
    optionsJSON: options,
    useBrowserAutofill: autofill,
  })
}

/**
 * 3. Submit the registration/authentication response to the server.
 *
 * This can be done by either:
 * 1. Use the `submitPasskey` function to submit the data to the server via a client-side request.
 * 2. Use the `signIn` function provided by next-auth to submit the data to the server via a client-side request.
 * 3. Submit a form with the following:
 *    -  Form's `action` attribute set to the provider's callback route (e.g. `/api/auth/callback/passkey`)
 *    -  Form's `method` attribute should be `POST`
 *    -  `action` and `data` as hidden inputs in the form.
 *
 * Use a form when a redirect is needed after the submission, such as on the login page.
 */

export async function submitPasskey<Action extends WebAuthnAction>({
  action,
  data,
  providerId,
}: {
  action: Action
  data: Action extends 'authenticate'
    ? AuthenticationResponseJSON
    : Action extends 'register'
      ? RegistrationResponseJSON
      : never
  providerId: ProviderId
}) {
  const res = await fetch(`${AUTH_PATH}/callback/${providerId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      action,
      data: JSON.stringify(data),
    }),
  })

  if (!res.ok) {
    throw new Error('Failed to submit form')
  }
}

/**
 * Attempts to authenticate the user when the page loads
 * using the browser's autofill popup.
 */
export async function autofillAuthentication() {
  const supportsAutofill = await browserSupportsWebAuthnAutofill()
  if (!supportsAutofill) return

  let options: PublicKeyCredentialRequestOptionsJSON | undefined
  try {
    options = await getWebauthnOptions({ action: 'authenticate' })
  } catch (_error) {
    console.error('Failed to fetch options for autofill authentication')
  }
  if (!options) return

  let data: AuthenticationResponseJSON | undefined
  try {
    data = await startPasskeyAuthentication(options, true)
  } catch (error) {
    if (error instanceof Error && error.name === 'AbortError') {
      return
    }
    throw error
  }
  if (!data) return

  return data
}
